import React, { Component, Fragment ,useState } from 'react'
import loader from "../../public/images/siteloader.gif"
import Image from 'next/image'
const SiteLoader = () =>{
    return (
        <Fragment>
			<div className="site-loader" style={{backgroundColor:"black"}}>
				<Image src={loader} alt="loader" height={'300px'} width={'300px'}/>
			</div>
        </Fragment>
    )
}
export default SiteLoader; 