import 'react-responsive-modal/styles.css';
// import 'sweetalert/dist/sweetalert.css';
import 'antd/dist/antd.css';
import 'rc-tooltip/assets/bootstrap_white.css';
import 'bootstrap/dist/css/bootstrap.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';
import "react-lightbox-pack/dist/index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "sweetalert/dist/sweetalert.min.js";
import '../public/css/style.css';
// import '../components/BU4Page/custom.css'
// import '../components/BU4Page/custom.scss'
// import '../components/BU4Page/custom-styles/_guidline.scss'
import './app.css';
import '../components/LandingPage/landingPage.css';
import './index.css';
import "../common_components/OverlayMenu/dist/react-overlay-menu.css"
import '../DefaultLayout/defaultLayout.css';
import "../components/ProductListingPage/components/ProductFilter.css"
import "../components/HomePage/HomePage.css"
import "../common_components/Common/Common.css"
import "../components/ProductDetailsPage/productDetails.css"
import "../components/ComparePage/comparePage.css"
// import '../components/ComparePage/comparePage.css';
import "../common_components/Common/login.css";
import "../components/UpcomingPage/components/Upcoming.css";
import "../common_components/Common/fb-grid/css/image.css"
// import "../components/CompanyProfilePage/ChargingStationOnMap/ChargingStation.css"
import "../components/SearchPage/searchPage.css"
import "../components/UserProfilePage/userProfilePage.css"
import "../components/NewsDetailsPage/newsPage.css"
import '../components/cms/cms.css'
import '../components/OlaPage/ola.css'
import "../components/CareerPage/index.css"
import { wrapper } from '../store/store';
import { Provider } from 'react-redux';
import Document from '../common_components/document';
import Head from 'next/head';
import DefaultHeader from '../DefaultLayout/DefaultHeader';
import DefaultFooter from '../DefaultLayout/DefaultFooter';
import ToastifyContainer from '../common_components/Common/ToastifyContainer';
import { GoogleAnalytics } from "nextjs-google-analytics";
import Router, { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Html } from 'next/document';
import SiteLoader from '../common_components/Common/SiteLoader';
import Script from 'next/script';
// import mixpanel from 'mixpanel-browser';
import Breadcrumbs from '../components/Breadcrumbs';
import "../components/BlogPage/BlogSidebar.css"

const App = ({ Component, pageProps }) => {
  const router = useRouter()
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    Router.events.on('routeChangeStart', (url) => {
      // console.log(typeof url, "<==============");
      setLoading(true);
    });
     
    // const urlArr = ["reviews", "blog", "overview", "news", "questions", "gallery" ]

    // Router.events.on('routeChangeStart', (url) => {

    //   const subCategory = url.split("/")[3];
    //   const x = urlArr.includes(subCategory);
    //   if (urlArr.includes(subCategory)) {
    //     setLoading(false);
    //   } else {
    //     setLoading(true);
    //   }
    // });

    Router.events.on('routeChangeComplete', (url) => {
      setLoading(false);
    });
    // var site =;
    // var canonicalURL =process.env.NEXT_PUBLIC_APP_BASE_URL + router.pathname;
    // console.log(canonicalURL)
    const loadMixpanel = async () => {
      const mixpanel = await import('mixpanel-browser');
      mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_ID, { track_pageview: true });
    };
    loadMixpanel();
  }, []);

  const getCanonicalUrl = (baseUrl, path) => {
    // Create a URL object from the given path and base URL
    const url = new URL(path, baseUrl);
  
    // Normalize the hostname (use the preferred hostname version)
    if (url.hostname.startsWith('www.')) {
      url.hostname = url.hostname.replace('www.', '');
    }

    if (url.pathname.includes('/vehicles')) {  
      // Replace "vehicles" in the pathname with "electric-vehicles"  
      url.pathname = url.pathname.replace('/vehicles', '/electric-vehicles');  
   }  
  
    // Remove fragment identifiers like `#!`
    url.hash = '';
  
    // Clear all query parameters
    url.search = '';
  
    // Return the URL as a string without the trailing slash
    return url.href.replace(/\/$/, '');
  };
  
  const canonicalUrl = getCanonicalUrl(process.env.NEXT_PUBLIC_APP_BASE_URL, router.asPath);

  // Determine if we're on a filtered page (?type=6)
  const isFilteredPage = router.query.type;

  return (
    // <>
    <html lang='en'>
       { process.env.NEXT_PUBLIC_META === "true" ? <Document /> : null}
      <Head>
        {/* <link rel="icon" href="%PUBLIC_URL%/logo-1.png" /> */}
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=yes'
        />
        <meta name='theme-color' content='#000000' />
        <link
          rel='shortcut icon'
          href='/logo-1.png'
          type='image/png'
          sizes='16x16'
        />
        {/* <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo-w.png" /> */}
        <link rel='manifest' href='/manifest.json' />
        
        {/* <link rel="canonical" href={process.env.NEXT_PUBLIC_APP_BASE_URL + router.asPath} /> */}
        <link rel="canonical" href={canonicalUrl} />

        {/* Alternate for Filtered Pages */}
        {isFilteredPage && (
          <link rel="alternate" href={`${process.env.NEXT_PUBLIC_APP_BASE_URL}${router.asPath}`} />
        )}

        {/* Optionally add noindex for filtered pages */}
        {/* {isFilteredPage && (
          <meta name="robots" content="noindex" />
        )} */}
        
        { process.env.NEXT_PUBLIC_META === "true" ?
        <>
        <title>
          EVINDIA | Electric Vehicle Marketplace in INDIA | Electric Scooters,
          Bikes & Electric Cars
        </title>
        <meta
          name='og:title'
          content='Electric Vehicle Marketplace in India | EVINDIA'
        />
        <meta
          name='og:description'
          content='Explore the expansive Electric Vehicle marketplace at EVINDIA, where we provide comprehensive information on electric vehicles to help you make informed choices'
        />
        <meta name='og:image' content='/evindia-og.png' />
        <meta name="ROBOTS" content="INDEX,FOLLOW,max-snippet:-1, max-image-preview:large" />
        <meta property="og:site_name" content="EVINDIA" />
        <meta property="og:type" content="website" />
        </> : '' }
      </Head>
      {loading ? (
        <SiteLoader />
      ) : (
        <>
        { process.env.NEXT_PUBLIC_META === "true" ?
        <>
          <Script
            id="tagmanager-main"
            strategy="afterInteractive"
            async
            src={`https://www.googletagmanager.com/gtag/js?id='AW-10904704145'`}
          ></Script>
          <Script
            id="tagmanager-setup"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-10904704145');
          `,
            }}
          />
          <Script id="facebook-pixel">
            {`!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod ?
          n.callMethod.apply(n, arguments) : n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', ${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID});
        fbq('track', 'PageView');
        `}
          </Script>
          <noscript><img height="1" width="1" style={{ display: 'none' }}
            src="https://www.facebook.com/tr?id=1241871939752892&ev=PageView&noscript=1"
          /></noscript>
          </> : '' }
          {/* <script type="text/javascript" src={"https://otpless.com/auth.js"} /> */}
          <DefaultHeader />
          <GoogleAnalytics trackPageViews />
          <Component {...pageProps} />
          <Breadcrumbs />
          <DefaultFooter />
        </>
      )}
      {/* Stray end tag "div" */}
      <ToastifyContainer />
    </html>
    // </>
  );
};

export default wrapper.withRedux(App)

