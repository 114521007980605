import { ERROR_VEHICLE_SAVED, GET_ALL_VEHICLE_SAVED,GET_EMPTY_SAVED } from '../actions/types'

const initialState = {
  saveVehicleList: {
    docs: [],
    totalDocs: 1,
    limit: 10,
    page: 1,
    totalPages: 1,
    pagingCounter: 1,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  loading: false,
}

//
const vehicleReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_ALL_VEHICLE_SAVED:
      return {
        ...state,
        saveVehicleList: payload,
        loading: false,
      }
    case GET_EMPTY_SAVED:
    return {
      ...state,
      saveVehicleList: payload,
      loading: false,
    }
    case ERROR_VEHICLE_SAVED:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
export default vehicleReducer
