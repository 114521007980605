import { GET_ALL_HOME_UPCOMING_LIST, GET_ALL_UPCOMING_LIST } from '../actions/types'

const initialState = {
  upcomingList: {
    docs: [],
    totalDocs: 1,
    limit: 10,
    page: 1,
    totalPages: 1,
    pagingCounter: 1,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  homeUpcomingList:[],
  loading: true,
}

const upCompingReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_ALL_UPCOMING_LIST:
      return {
        ...state,
        upcomingList: payload,
        loading: false,
      }
      case GET_ALL_HOME_UPCOMING_LIST:
      return{
        ...state,
        homeUpcomingList: payload,
        loading:false,
      }
    default:
      return state
  }
}
export default upCompingReducer
