import {
  APPLY_FILTER,
  GET_CITIES,
  GET_FILTERED_VEHICLES,
  GET_POPULAR_VEHICLES,
  GET_WAITING_PERIOD,
  GET_TOP_TEN_COMPANIES,
  CLEAR_FILTER,
  GET_LATEST_VEHICLES,
  CITY_SELECT,
  STATE_SELECT,
  VEHICLE_SELECT,
  GET_VEHICLE_RANGE,
  GET_FILTERED_COMPANIES,
  GET_STATES,
  GET_DEALER_CITIES,
  GET_COMPARE_VEHICLE,
  GET_LATEST_ELECTRIC_SCOOTER,
  GET_LATEST_ELECTRIC_BIKE,
  GET_LATEST_ELECTRIC_CAR,
  GET_COMMERCIAL_VEHICLES,
  GET_FAQ_LIST,
  GET_LATEST_REVIEWS,
  GET_BLOG_LIST,
  GET_BLOG_NEWS_LIST,
  BU4_PRODUCTS,
  GET_HOMEPAGE_BANNER_IMAGE,
  GET_HOMEPAGE_LP_BANNER_IMAGE,
  POPULAR_IMAGES,
  FILTER_VALUES
} from '../actions/types';

const initialState = {
  cities: [],
  dealerCities: [],
  // cityLoading: true,
  states: [],
  stateLoading: true,
  filteredVehicles: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 1,
    totalPages: 1,
    pagingCounter: 1,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  popularVehicles: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 1,
    totalPages: 1,
    pagingCounter: 1,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  latestVehicles: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 1,
    totalPages: 1,
    pagingCounter: 1,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  toptencompanies: {
    result: []
  },
  vehiclerange: {},
  filteredCompanies: [],
  companiesLoading: true,
  vehiclerangeLoading: true,
  vehicleLoading: true,
  toptencompaniesLoading: true,
  isFilter: true,
  waitingPeriod: [],
  citydata: [],
  cityloading: true,
  resetvehiclesearch: false,
  comparevehicles: [],
  latestElectricScooter: [],
  latestElectricBike: [],
  latestElectricCar: [],
  commercialVehicles: [],
  faqList: [],
  latestReviews: [],
  blogdata: [],
  statedata: [],
  stateloading: true,
  resetvehiclesearch: false,
  sponserproducts: [],
  sponserproductsloading: true,
  backgroundBannerImages: [],
  popularimages: [],
  popularImagesLoading: true,
  filterData: {
    page: 1,
    limit: 9
  },
  filterDataLoading: true
};

const homePageReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CITIES:
      return {
        ...state,
        cities: payload,
        cityLoading: false,
      };
    case GET_DEALER_CITIES:
      return {
        ...state,
        dealerCities: payload,

      };
    case GET_STATES:
      return {
        ...state,
        states: payload,
        stateLoading: false,
      };
    case GET_WAITING_PERIOD:
      return {
        ...state,
        waitingPeriod: payload,
      };
    case GET_FILTERED_VEHICLES:
      return {
        ...state,
        filteredVehicles: payload,
        vehicleLoading: false,
      };
    case GET_FILTERED_COMPANIES:
      return {
        ...state,
        filteredCompanies: payload,
        companiesLoading: false,
      };
    case GET_LATEST_ELECTRIC_SCOOTER:
      return {
        ...state,
        latestElectricScooter: payload,
        vehicleLoading: false,
      };
    case GET_LATEST_ELECTRIC_BIKE:
      return {
        ...state,
        latestElectricBike: payload,
        vehicleLoading: false,
      };
    case GET_LATEST_ELECTRIC_CAR:
      return {
        ...state,
        latestElectricCar: payload,
        vehicleLoading: false,
      };
    case GET_COMMERCIAL_VEHICLES:
      return {
        ...state,
        commercialVehicles: payload,
        vehicleLoading: false,
      };
    case GET_POPULAR_VEHICLES:
      return {
        ...state,
        popularVehicles: payload,
        vehicleLoading: false,
      };
    case GET_LATEST_VEHICLES:
      return {
        ...state,
        latestVehicles: payload,
        vehicleLoading: false,
      };
    case APPLY_FILTER:
      return {
        ...state,
        isFilter: payload,
      };
    case CLEAR_FILTER:
      return {
        ...state,
        filteredVehicles: payload,
        vehicleLoading: true,
      }
    case GET_TOP_TEN_COMPANIES:
      return {
        ...state,
        toptencompanies: payload,
        toptencompaniesLoading: false
      }
    case CITY_SELECT:
      return {
        ...state,
        citydata: payload,
        cityloading: false,
      }
    case STATE_SELECT:
      return {
        ...state,
        statedata: payload,
        stateloading: false,
      }
    case VEHICLE_SELECT:
      return {
        ...state,
        resetvehiclesearch: true,
      }
    case GET_VEHICLE_RANGE:
      return {
        ...state,
        vehiclerange: payload,
        vehiclerangeLoading: false,
      }
    case GET_COMPARE_VEHICLE:
      return {
        ...state,
        comparevehicles: payload,
      }
    case GET_FAQ_LIST:
      return {
        ...state,
        faqList: payload
      }
    case GET_LATEST_REVIEWS:
      return {
        ...state,
        latestReviews: payload
      }
    case GET_BLOG_LIST:
      return {
        ...state,
        blogdata: payload
      }
    case GET_BLOG_NEWS_LIST:
      return {
        ...state,
        blogandnewsdata: payload
      }
    case BU4_PRODUCTS:
      return {
        ...state,
        sponserproducts: payload,
        sponserproductsloading: false,
      }
    case GET_HOMEPAGE_BANNER_IMAGE:
      return {
        ...state,
        backgroundBannerImages: payload,
      }
    case GET_HOMEPAGE_LP_BANNER_IMAGE:
      return {
        ...state,
        backgroundBannerLpImages: payload,
      }
    case POPULAR_IMAGES:
      return {
        ...state,
        popularimages: payload,
        popularImagesLoading: false
      }
    case FILTER_VALUES:
      return {
        ...state,
        filterData: payload,
        filterDataLoading: false
      }
    default:
      return state;
  }

};

export default homePageReducer;
