import {
  GET_COMPANY_POST_LIST,
  GET_RANDOM_COMPANY_LIST,
  GET_ALL_COMPANY_LIST,
  GET_COMPANY_DETAILS,
  GET_ALL_COMPANY_LIST_WITHOUT_PAGINATION,
  GET_COMPANY_INFRASTRUCTURES,
  COMPANY_DETAILS_LOADING,
} from "../actions/types";

const initialState = {
  allCompanies: {
    docs: [],
    totalDocs: 1,
    limit: 10,
    page: 1,
    totalPages: 1,
    pagingCounter: 1,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  allCompanyLoading: true,
  companyPostListing: {
    docs: [],
    totalDocs: 1,
    limit: 10,
    page: 1,
    totalPages: 1,
    pagingCounter: 1,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  companyPostLoading: true,
  randomCompanyList: [],
  randomCompanyLoading: true,
  companyDetails: {},
  companyDetailsLoading: true,
  companyData: [],
  companyDataLoading: true,
  companyInfraList: {
    docs: [],
  },
  companyInfraLoading: true,
  companyVehicles: [],
  companyVehiclesLoading: true,
};

const companyReducers = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_COMPANY_LIST:
      return {
        ...state,
        allCompanies: payload,
        allCompanyLoading: false,
      };
    case GET_ALL_COMPANY_LIST_WITHOUT_PAGINATION:
      return {
        ...state,
        companyData: payload,
        companyDataLoading: false,
      };
    case GET_COMPANY_DETAILS:
      return {
        ...state,
        companyDetails: payload,
        companyDetailsLoading: false,
      };
    case GET_COMPANY_POST_LIST:
      return {
        ...state,
        companyPostListing: payload,
        companyPostLoading: false,
      };
    case GET_RANDOM_COMPANY_LIST:
      return {
        ...state,
        randomCompanyList: payload,
        randomCompanyLoading: false,
      };
    case GET_COMPANY_INFRASTRUCTURES:
      return {
        ...state,
        companyInfraList: payload,
        companyInfraLoading: false,
      };
    case COMPANY_DETAILS_LOADING:
      return {
        ...state,
        companyDetailsLoading: true,
        companyDetails: {},
      };

    default:
      return state;
  }
};
export default companyReducers;
