import { ACTIVE_TAB_ON_CLICK } from "../actions/types";

const initialState = {
    activeTab: ['1'],
  };

  const activeTab = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case ACTIVE_TAB_ON_CLICK:
        return {
          activeTab: payload,
        };
      default:
        return state;
    }
  };
  
  export default activeTab;