import {
  GET_UPCOMING_VEHICLES,
  GET_VEHICLE_DETAILS,
  COMPARE_VEHICLE,
  GET_MAIN_VEHICLES,
  GET_VEHICLE_VARIANTS,
  DEALERS_DETAILS,
  DEALERS_DETAILS_BRANDS,
  DEALER_PROFILE,
  DEALER_PROFILE_VEHICLE,
  COMPANY_AVAILABLE_VEHICLES,
  LOAD_COMPANY_AVAILABLE_VEHICLES,
  USER_BY_VEHICLE_ID,
  DEALER_PROFILE_BRANDS,
  GET_SIMILIAR_VEHICLE,
  GET_VEHICLE_COLOR_LIST,
  GET_VEHICLE_LIST_REVIEW,
  GET_VEHICLE_OVERVIEW_LIST,
  GET_VEHICLE_QUESTION_ANS,
  HANDLE_SELECTED_CITY,
  HANDLE_SELECTED_STATE
} from "../actions/types";

const initialState = {
  vehicleDetails: null,
  loading: true,
  upcomingVehicles: [],
  upcomingLoading: true,
  compareVehicles: [],
  compareLoading: true,
  mainVehicles: [],
  mainVehiclesLoading: true,
  vehicleVariants: [],
  vehicleColorList: [],
  vehicleReviewList: [],
  variantsLoading: true,
  vehicleDealers: [],
  dealersLoading: true,
  dealersDeatils: [],
  dealersdetailLoading: true,
  vehicleDealersBrands: [],
  brandDealersLoading: true,
  dealersprofile: [],
  dealersprofileLoading: true,
  dealersprofilebrands: [],
  dealersprofilebrandsLoading: true,
  userdetailsLoading: true,
  userdetails: [],
  companyVehicles: { docs: [], page: 1 },
  allCompanyVehicles: [],
  companyVehiclesLoading: true,
  similiarVehicles: [],
  similiarVehiclesLoading: true,
  vehicleQna: [],
  vehicleOverviewList: [],
  selectedCity: null,
  selectedState: {
    label: "Gujarat",
    value: "Gujarat",
    _id: "61e66a7a2e0f24388ffccc13",
  },
  stateLoading: true,
};

const productReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_VEHICLE_DETAILS:
      return {
        ...state,
        vehicleDetails: payload,
        loading: false,
      };
    case GET_UPCOMING_VEHICLES:
      return {
        ...state,
        upcomingVehicles: payload,
        upcomingLoading: false,
      };
    case COMPARE_VEHICLE:
      return {
        ...state,
        compareVehicles: payload,
        compareLoading: false,
      };
    case GET_MAIN_VEHICLES:
      return {
        ...state,
        mainVehicles: payload,
        mainVehiclesLoading: false,
      };
    case GET_VEHICLE_VARIANTS:
      return {
        ...state,
        vehicleVariants: payload,
        variantsLoading: false,
      };
    case GET_VEHICLE_COLOR_LIST:
      return {
        ...state,
        vehicleColorList: payload,
      };
    case GET_VEHICLE_LIST_REVIEW:
      return {
        ...state,
        vehicleReviewList: payload,
      };
    case DEALERS_DETAILS:
      return {
        ...state,
        vehicleDealers: payload,
        dealersLoading: false,
      };
    case DEALERS_DETAILS_BRANDS:
      return {
        ...state,
        vehicleDealersBrands: payload,
        brandDealersLoading: false,
      };
    case DEALER_PROFILE:
      return {
        ...state,
        dealersDeatils: payload,
        dealersdetailLoading: false,
      };
    case DEALER_PROFILE_VEHICLE:
      return {
        ...state,
        dealersprofile: payload,
        dealersprofileLoading: false,
      };
    case DEALER_PROFILE_BRANDS:
      return {
        ...state,
        dealersprofilebrands: payload,
        dealersprofilebrandsLoading: false,
      };
    case USER_BY_VEHICLE_ID:
      return {
        ...state,
        userdetails: payload,
        userdetailsLoading: false,
      };
    case LOAD_COMPANY_AVAILABLE_VEHICLES:
      return {
        ...state,
        companyVehiclesLoading: true,
      };
    case COMPANY_AVAILABLE_VEHICLES:
      return {
        ...state,
        companyVehicles: payload,
        companyVehiclesLoading: false,
      };
    case GET_SIMILIAR_VEHICLE:
      return {
        ...state,
        similiarVehicles: payload,
        similiarVehiclesLoading: false,
      };
    case GET_VEHICLE_QUESTION_ANS:
      return {
        ...state,
        // vehicleQna: payload,
        vehicleQna: [ action.payload],
      };
    case GET_VEHICLE_OVERVIEW_LIST: {
      return {
        ...state,
        vehicleOverviewList: payload,
      };
    }
    case HANDLE_SELECTED_CITY: {
      return {
        ...state,
        selectedCity: payload,
      };
    }
    case HANDLE_SELECTED_STATE: {
      return {
        ...state,
        selectedState: payload,
        stateLoading: false,
      };
    }
    default:
      return state;
  }
};

export default productReducer;
