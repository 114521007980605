import { GET_EVENT_DETAILS, GET_EVENT_LIST } from '../actions/types';
const initialState = {
  eventList: {
    docs: [],
    totalDocs: 1,
    limit: 10,
    page: 1,
    totalPages: 1,
    pagingCounter: 1,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  companyEventList: [],
  detailsEvent: [],

  loading: true,
};

const eventList = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_EVENT_LIST:
      return {
        ...state,
        companyEventList: payload,
        loading: false,
      };
    case GET_EVENT_DETAILS:
      return {
        ...state,
        detailsEvent: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default eventList;
