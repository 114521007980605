import { combineReducers } from 'redux'
import companyReducers from './companyReducers'
import homePageReducer from './homePageReducer'
import productReducer from './productReducer'
import serviceReducer from './serviceReducers'
import upCompingReducer from './upcomingReducer'
import userReducer from './userReducer'
import saveVehicleReducer from './saveVehicleReducer'
import demoReducer from './demoReducer'
import dealerReducer from './dealerReducer'
import eventList from './eventReducer';
import comapnyGallery from './galleryReducer';
import activeAccordianKey from './accordianKeyReducer';
import compareVehicles from './compareVehicleReducer';
import activeTab from './activeTabReducer'
import CareerReducer from './careerReducers'
import landingPageReducer from './landingPageReducer'
export default combineReducers({
  user: userReducer,
  company: companyReducers,
  home: homePageReducer,
  vehicle: productReducer,
  service: serviceReducer,
  upcoming: upCompingReducer,
  saveVehicle: saveVehicleReducer,
  demousers: demoReducer,
  dealer: dealerReducer,
  event: eventList,
  gallery: comapnyGallery,
  accordian: activeAccordianKey,
  compare: compareVehicles,
  tab:activeTab,
  career: CareerReducer,
  landingPage: landingPageReducer
});
