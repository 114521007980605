import { GET_LP_DETAILS_BY_COMPANY, GET_VEHICLE_BY_ID } from "../actions/types";

const initialState = {
    landingPageDetails: {},
    landingPageDetailsLoading: true,
    
}

const landingPageReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_LP_DETAILS_BY_COMPANY:
            return {
              ...state,
              landingPageDetails: payload,
              landingPageDetailsLoading: false,
            }
            default:
                return state;
            }
          
          };
          
 export default landingPageReducer;