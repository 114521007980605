import { GET_GALLERY_IMAGES, GET_GALLERY_VIDEOS } from '../actions/types';

const initialState = {
  gallery: {
    docs: [],
    totalDocs: 1,
    limit: 10,
    page: 1,
    totalPages: 1,
    pagingCounter: 1,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  companyImageList: [],
  loadingCompanyImageList: true,
  companyVideoList: [],
  loadingCompanyVideoList: true,
};

const comapnyGallery = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_GALLERY_IMAGES:
      return {
        ...state,
        companyImageList: payload,
        loadingCompanyImageList: false,
      };
    case GET_GALLERY_VIDEOS:
      return {
        ...state,
        companyVideoList: payload,
        loadingCompanyVideoList: false,
      };
    default:
      return state;
  }
};

export default comapnyGallery;
