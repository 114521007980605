import { event } from "nextjs-google-analytics";
import mixpanel from 'mixpanel-browser';


const Analytics = (props) => {
    if (props?.GA) {
        event(props?.title, {
            category: props?.category,
            action: props?.action,
            label: props?.label,
            value: 1,
        });
    }
    if (props?.MP) {
        const propsObj = {
            category: props?.category,
            action: props?.action,
            label: props?.label,
        }
        if (props?.type === 2) propsObj.value = props?.fieldValue
        mixpanel.track(props?.title, propsObj);
    }

}

export default Analytics