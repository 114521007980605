import { SET_COMPARE_VEHICLE, SET_COMPARE_VEHICLE_ID, SET_COMPARE_VEHICLE_SPECIFICATION } from '../actions/types';

const initialState = {
  compareVehicle: [],
  compareVehicleId: [],
  compareVehicleSpecification: [],
  compareVehicleSpecificationLoading: true
};

const compareVehicles = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_COMPARE_VEHICLE:
      return {
        ...state,
        compareVehicle: payload,
      };
      case SET_COMPARE_VEHICLE_SPECIFICATION:
        return{
          ...state,
          compareVehicleSpecification: payload,
          compareVehicleSpecificationLoading: false
        };
    case SET_COMPARE_VEHICLE_ID:
      return {
        ...state,
        compareVehicleId: payload,
      };
    default:
      return state;
  }
};

export default compareVehicles;
