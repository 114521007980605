import {
  GET_COMMUNITY_FORUM,
  GET_COMPANY_LIST,
  GET_COMPANY_POST_LIST,
  GET_RANDOM_COMPANY_LIST,
  GET_GENERAL_COMPANY_LIST,
  GET_ALL_SPECIFICATIONS,
  GET_ALL_VEHICLE_NEWS,
  GET_ALL_COMPANY_NEWS,
  GET_RANDOM_NEWS,
  GET_HOMEPAGE_NEWS,
  CLEAR_ALL_NEWS_DATA,
  GET_NEWS_DETAILS,
  GET_BLOG_LISTING,
  GET_FEATURE_NEW,
  ADD_LOCAL,
  CLEAR_LOCAL,
  GET_BLOG_DETAILS,
  GET_LIST_SIMILAR_NEWS,
  GET_SIMILAR_BLOGS,
  GET_SIMILAR_NEWS
} from '../actions/types';

const initialState = {
  communityForum: {
    docs: [],
    totalDocs: 1,
    limit: 10,
    page: 1,
    totalPages: 1,
    pagingCounter: 1,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  similarNews:[],
  communityLoading: true,
  upcommingVehicleList: [],
  upcommingLoading: true,
  specificationsList: [],
  specificationLoading: true,
  applyFilter: false,
  companyListingReq: {
    docs: [],
    totalDocs: 1,
    limit: 10,
    page: 1,
    totalPages: 1,
    pagingCounter: 1,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  companyListingLoading: true,

  companyPostListing: {
    docs: [],
    limit: 10,
    page: 1,
    hasNextPage: false,
  },
  companyPostLoading: true,
  randomCompanyList: [],
  randomCompanyLoading: true,
  generalCompanyList: [],
  generalCompanyLoading: true,
  vehicleNewsList: {
    docs: [],
  },
  allVehicleNewsData: [],
  companyFeaturedList: {
    docs: [],
  },
  loadingCompanyFeaturedList: true,
  companyNewsList: {
    docs: [],
  },
  allCompanyNewsData: [],
  newsLoading: true,
  companyNewsLoading: true,
  randomNews: [],
  randomNewsLoading: true,
  homepageNews: {
    docs: [],
    limit: 10,
    page: 1,
    hasNextPage: false,
  },
  allNewsData: [],
  homepageNewsLoading: true,
  newsdetails: [],
  newsDetailsloading: true,
  allBlogData: [],
  allblogloading: true,
  blogDetails:[],
  localstorageloading: true,
  localStorageData: [],
  similarBlogs:[],
  similarNews:[]
};

const serviceReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_COMMUNITY_FORUM:
      return {
        ...state,
        communityForum: payload,
        communityLoading: false,
      };

    case GET_COMPANY_LIST:
      return {
        ...state,
        companyListingReq: payload,
        companyListingLoading: false,
      };
    case GET_COMPANY_POST_LIST:
      return {
        ...state,
        companyPostListing: payload,
        companyPostLoading: false,
      };
    case GET_RANDOM_COMPANY_LIST:
      return {
        ...state,
        randomCompanyList: payload,
        randomCompanyLoading: false,
      };
    case GET_GENERAL_COMPANY_LIST:
      return {
        ...state,
        generalCompanyList: payload,
        generalCompanyLoading: false,
      };
    case GET_ALL_SPECIFICATIONS:
      return {
        ...state,
        specificationsList: payload,
        specificationLoading: false,
      };
    case GET_ALL_VEHICLE_NEWS:
      return {
        ...state,
        vehicleNewsList: payload,
        newsLoading: false,
      };
    case GET_ALL_COMPANY_NEWS:
      return {
        ...state,
        companyNewsList: payload,
        companyNewsLoading: false,
      };
    case GET_FEATURE_NEW:
      return {
        ...state,
        companyFeaturedList: payload,
        loadingCompanyFeaturedList: false,
      };
    case GET_RANDOM_NEWS:
      return {
        ...state,
        randomNews: payload,
        randomNewsLoading: false,
      };
    case GET_HOMEPAGE_NEWS:
      return {
        ...state,
        homepageNews: payload,
        allNewsData: payload,
        homepageNewsLoading: false,
      };
    case CLEAR_ALL_NEWS_DATA:
      return {
        ...state,
        allNewsData: [],
        homepageNewsLoading: true,
      };
    case GET_NEWS_DETAILS:
      return {
        ...state,
        newsdetails: payload,
        newsDetailsloading: false,
      };
    case GET_BLOG_LISTING:
      return {
        ...state,
        allBlogData: payload,
        allblogloading: false,
      };
      case GET_BLOG_DETAILS:
        return {
          ...state,
          blogDetails: [...state.blogDetails,action.payload],
          // blogDetails:payload
          // allblogloading: false,
        };
      case GET_SIMILAR_BLOGS:
        return {
          ...state,
          similarBlogs:payload
        };
      case GET_SIMILAR_NEWS:
        return {
          ...state,
          similarNews:payload
        };
      case ADD_LOCAL:
        return {
          ...state,
          localstorageloading: false,
          localStorageData: payload
        };
      case CLEAR_LOCAL:
        return {
          ...state,
          localstorageloading: true,
        }   
      case GET_LIST_SIMILAR_NEWS:
        return{
          ...state,
          similarNews:payload
        }
    default:
      return state;
  }
};
export default serviceReducer;