import { JOB_LIST, JOB_DETAILS } from "../actions/types";

const initialState = {
    jobList: [],
    joblistLoading: true,
    jobDetails: [],
    jobDetailsLoading: true
}

const CareerReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case JOB_LIST:
            return { ...state, joblistLoading: false, jobList: payload }
        case JOB_DETAILS:
            return { ...state, jobDetailsLoading: false, jobDetails: payload }
        default:
            return state
    }
}

export default CareerReducer