import {
  GET_USER_OWNED_VEHICLE_EXPERIANCE,
  DEALER_VEHICLE,
  DEALER_POSTS,
  DEALER_GALLERY,
  DEALER_REVIEWS,
  DEALER_BRANDS,
  DEALER_VIDEO,
  DEALER_PROFILE,
  GET_DEALER_PRICE_BREAKUP,
  GET_DEALER_LEAD,
  GET_DEALER_CRM,
  GET_DEALER_WALLET
} from '../actions/types';
const initialState = {
  token:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Imt1c2hhbEBnbWFpbC5jb20iLCJpZCI6IjYxNjUzOTIwMzkwMzRiMzBkODgyZmY5NCIsImlhdCI6MTYzODc4NzQ5NSwiZXhwIjoxNjcwMzIzNDk1fQ.9_ylQKLt7V51TbQ62uriKewt0zx30tziMyeE84WG5M4',
  isAuthenticated: null,
  loading: true,
  user: null,
  otheruser: [],
  otherUserLoading: true,
  uservehicles: [],
  userVehiclesLoading: true,
  myEVJourney: [],
  journeyLoading: true,
  dealerPosts: [],
  dealerPostLoading: true,
  dealerReviews: [],
  dealerReviewsLoading: true,
  dealersBrands: [],
  dealersBrandsLoading: true,
  userTimeLine: {
    docs: [],
    page: 1,
    totalPages: 1,
    hasPrevPage: false,
    hasNextPage: false,
  },
  userTimeLineLoading: true,
  ownedVehicleLoading: true,
  ownedVehicles: [],
  ownedVehicleexperianceLoading: true,
  ownedVehiclesexperiance: [],
  dealerVehicle: [],
  dealerPriceBreakUp: [],
  dealerVehicleLoading: true,
  dealerGallery: [],
  dealerGalleryLoading: true,
  dealerVideo: [],
  dealerVideoLoading: true,
  dealerDetails: [],
  dealerDetailsloading: true,
  dealerLead: [],
  dealerLeadLoading: true,
  dealerCRM: [],
  dealerCRMLoading: true,
  dealerWallet: [],
  dealerWalletLoading: true
};

const dealerReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_USER_OWNED_VEHICLE_EXPERIANCE:
      return {
        ...state,
        ownedVehicleexperianceLoading: false,
        ownedVehiclesexperiance: payload,
      };

    case DEALER_VEHICLE:
      return {
        ...state,
        dealerVehicleLoading: false,
        dealerVehicle: payload,
      };
    case GET_DEALER_PRICE_BREAKUP:
      return {
        ...state,
        dealerPriceBreakUp: payload
      }
    case DEALER_PROFILE:
      return {
        ...state,
        dealerDetailsloading: false,
        dealerDetails: payload,
      };

    case DEALER_POSTS:
      return {
        ...state,
        dealerPostLoading: false,
        dealerPosts: payload,
      };

    case DEALER_GALLERY:
      return {
        ...state,
        dealerGalleryLoading: false,
        dealerGallery: payload,
      };
    case DEALER_VIDEO:
      return {
        ...state,
        dealerVideoLoading: false,
        dealerVideo: payload,
      };

    case DEALER_REVIEWS:
      return {
        ...state,
        dealerReviewsLoading: false,
        dealerReviews: payload,
      };

    case DEALER_BRANDS:
      return {
        ...state,
        dealersBrands: payload,
        dealersBrandsLoading: false,
      };
    case GET_DEALER_WALLET: {
      return {
        ...state,
        dealerWallet: payload,
        dealerWalletLoading: false
      }
    }
    case GET_DEALER_CRM: {
      return {
        ...state,
        dealerCRM: payload,
        dealerCRMLoading: false
      }
    };
    case GET_DEALER_LEAD:
      return {
        ...state,
        dealerLead: payload,
        dealerLeadLoading: false
      };
    default:
      return state;
  }
};

export default dealerReducer;
