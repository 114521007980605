import React from 'react';
import { PulseLoader } from 'react-spinners';
import notify from '../public/images/bell-ring.png';
import close from '../public/images/close.png';
import google from "../public/images/Google.png"
import { css } from '@emotion/react';
import Tooltip from 'rc-tooltip';
import Image from "next/image"

export function Button({ className, click, title }) {
  return (
    <button className={`btn  ${className}`} onClick={click}>
      <span>{title}</span>
    </button>
  );
}

export function BlueButton({ title, className, onClick, disabled }) {
  return (
    <button
      className={`btn blue-btn px-5 px-md-4 px-lg-4 px-xl-5  me-4 me-md-2 me-lg-3 ${className}`}
      onClick={onClick && onClick}
      disabled={disabled}
    >
      {title}
    </button>
  );
}

export function BlueBorderButton({ title, className }) {
  return (
    <button
      className={`btn blue-btn blue-border-btn px-md-4 px-lg-4 px-xl-5 px-5 ${className}`}
    >
      {title}
    </button>
  );
}
const override = css`
  display: block;
  margin: 0 auto;
  border-color: blue;
  color: blue;
`;

export function NotifyButton({ className, onClick, title }) {
  return (
    <button
      className={`btn notify-launch d-flex align-items-center justify-content-center ${className}`}
      onClick={(e) => onClick(e)}
    >
      <img src={notify} className='me-2' width='13' alt='bell-icon' />
      Notify me on launch
    </button>
  );
}

export function YellowButton({ className, click, title, loading }) {
  return (
    <button className={`btn btn-primary px-5 ${className}`} onClick={click}>
      {loading ? (
        <PulseLoader color={'#fff'} size={8} css={override} />
      ) : (
        'Post'
      )}
      <span>{title}</span>
    </button>
  );
}
export const closeIcon = (
  <>
    <Image
      src={close}
      className="shadow-sm rounded-circle"
      alt="cancel-icon"
      width="25"
      height="25"
      loading="lazy"
    />
  </>
);

export const INRFormat = (price) => {
  let p = Intl.NumberFormat('en-IN', {
    // maximumSignificantDigits: 3,
    style: 'currency',
    currency: 'INR',
  }).format(price);

  return (p = p.split('.')[0]);
};
export const PriceConvertor = (price, hint) => {
  var val = Math.abs(price)
  if (val >= 10000000) {
    val = hint === "start" ? (val / 10000000).toFixed(2) : (val / 10000000).toFixed(2) + ' Cr';
    // val = result.substring(0,1)+result.substring(1);
  } else if (val >= 99999) {
    val = hint === "start" ? (val / 100000).toFixed(2) : (val / 100000).toFixed(2) + ' Lakh';
    // val = res.substring(0,1)+res.substring(1);
  }
  else {
    val = Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(price);
  }
  return val;

}

export const convertDoubleToSingleQuotes = (inputString) => {
  return inputString.replace(/"/g, "'");
};


export const CustomTooltip = (props) => {
  return (
    <Tooltip placement={props.pos} overlay={<span>{props.title}</span>}>
      {props.children}
    </Tooltip>
  );
};

export const Input = (props) => {
  return (
    <>
      <label className='mb-2 color-d-gray'>{props.label}</label>
      <input name={props.name} type={props.type} className={`common-input border col-12  py-2 px-3 ${props.className}`} placeholder={props.placeholder} onChange={props.onChange && props.onChange}></input>
    </>
  )
}

export const SocialButton = ({ redirectTo, imagelink, social }) => {
  return (
    <a href={redirectTo}>
      <button
        className='btn border common-input col-12 d-flex align-items-center justify-content-center py-2 px-3'
      // onClick={(e) => onClick(e)}
      >
        <Image
          src={imagelink}
          width='22'
          height='22'
          alt={social}
          loading='lazy'
          sizes="320 640 750"
          layout='fixed'
          className='d-block'
        />
        <span className='ms-2' style={{ fontSize: "14px" }}>{`Continue with ${social}`}</span>
      </button>
    </a>
  )
}
