import axios from "axios";
import { toast } from "react-toastify";
import {
  Get_Community_Details,
  company_listing,
  company_post_listing,
  Get_All_Specifications,
  Get_News,
  Get_Random_News,
  Subscribe,
  config,
  get_cms,
  Get_News_Details,
  Get_Blog,
  Get_Feature_News,
  similarNewsList,
  Get_Similar_Blogs,
  Get_Similar_News
} from '../api';
import setAuthToken from "../helpers/setAuthToken";
import {
  CLEAR_ALL_NEWS_DATA,
  GET_ALL_COMPANY_NEWS,
  GET_ALL_SPECIFICATIONS,
  GET_ALL_VEHICLE_NEWS,
  GET_COMMUNITY_FORUM,
  GET_COMPANY_LIST,
  GET_COMPANY_POST_LIST,
  GET_HOMEPAGE_NEWS,
  GET_RANDOM_NEWS,
  GET_NEWS_DETAILS,
  GET_BLOG_LISTING,
  GET_FEATURE_NEW,
  ADD_LOCAL,
  CLEAR_LOCAL,
  GET_BLOG_DETAILS,
  GET_LIST_SIMILAR_NEWS,
  GET_SIMILAR_BLOGS,
  GET_SIMILAR_NEWS,
} from './types';

export const getCommunityDetails = () => async (dispatch) => {
  try {
    const res = await axios.get(Get_Community_Details);
    if (res) {
      dispatch({
        type: GET_COMMUNITY_FORUM,
        payload: res.data.result,
      });
    }
    return res;
  } catch (err) {
    return err;
  }
};

export const getCompnayList = () => async (dispatch) => {
  try {
    const res = await axios.get(company_listing);
    if (res) {
      dispatch({
        type: GET_COMPANY_LIST,
        payload: res.data.result,
      });
    }
    return res;
  } catch (err) {
    return err;
  }
};

export const getCompnayPostList = () => async (dispatch) => {
  try {
    const res = await axios.get(company_post_listing);
    if (res) {
      dispatch({
        type: GET_COMPANY_POST_LIST,
        payload: res.data.result,
      });
    }
    return res;
  } catch (err) {
    return err;
  }
};

export const getAllSpecifications = () => async (dispatch) => {
  try {
    const res = await axios.get(Get_All_Specifications);
    if (res) {
      dispatch({
        type: GET_ALL_SPECIFICATIONS,
        payload: res.data.result,
      });
    }
    return res;
  } catch (err) {
    return err;
  }
};

export const getAllNewsRandom = () => async (dispatch) => {
  setAuthToken(
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Imt1c2hhbEBnbWFpbC5jb20iLCJpZCI6IjYyMmYxMzg0ODVlNzRjMjQzM2M4NGVmMCIsImlhdCI6MTY0NzI1MjM5NSwiZXhwIjoxNjc4Nzg4Mzk1fQ.nGKqS_-5AbzcyYlDHbLwSoSHR-xT10RDGOpRpbaNPKE"
  );
  try {
    const res = await axios.get(Get_Random_News);
    if (res) {
      dispatch({
        type: GET_RANDOM_NEWS,
        payload: res.data.result,
      });
    }
    return res;
  } catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
};
export const getSimilarBlogs = (tags) => async (dispatch) => {
  setAuthToken(
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Imt1c2hhbEBnbWFpbC5jb20iLCJpZCI6IjYyMmYxMzg0ODVlNzRjMjQzM2M4NGVmMCIsImlhdCI6MTY0NzI1MjM5NSwiZXhwIjoxNjc4Nzg4Mzk1fQ.nGKqS_-5AbzcyYlDHbLwSoSHR-xT10RDGOpRpbaNPKE"
  );
  try {
    const res = await axios.get(Get_Similar_Blogs + "tagId=" + tags);
    if (res) {
      dispatch({
        type: GET_SIMILAR_BLOGS,
        payload: res.data.result,
      });
    }
    return res;
  } catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
};
export const getSimilarNews = (tags) => async (dispatch) => {
  setAuthToken(
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Imt1c2hhbEBnbWFpbC5jb20iLCJpZCI6IjYyMmYxMzg0ODVlNzRjMjQzM2M4NGVmMCIsImlhdCI6MTY0NzI1MjM5NSwiZXhwIjoxNjc4Nzg4Mzk1fQ.nGKqS_-5AbzcyYlDHbLwSoSHR-xT10RDGOpRpbaNPKE"
  );
  try {
    const res = await axios.get(Get_Similar_News + "tagId=" + tags);
    if (res) {
      dispatch({
        type: GET_SIMILAR_NEWS,
        payload: res.data.result,
      });
    }
    return res;
  } catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
};
export const getAllNews = (dataObj) => async (dispatch) => {
  try {
    const res = await axios.get(Get_News, { params: dataObj });
    if (dataObj.type === 1) {
      dispatch({
        type: GET_ALL_VEHICLE_NEWS,
        payload: res.data.result,
      });
    }
    if (dataObj.type === 2) {
      dispatch({
        type: GET_ALL_COMPANY_NEWS,
        payload: res.data.result,
      });
    }
    return res;
  } catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
};

export const getFeatureNew = (other) => async (dispatch) => {
  try {
    const res = await axios.get(Get_Feature_News + other);
    dispatch({
      type: GET_FEATURE_NEW,
      payload: res.data.result,
    });
    return res;
  } catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
};
export const subscribeUser = (data) => async (dispatch) => {
  const body = JSON.stringify(data);
  try {
    const res = await axios.post(Subscribe, body, config);
    if (res) toast.success(res.data.message);
    return res;
  } catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
};

export const getHomePageNews = (newsObj) => async (dispatch) => {
  setAuthToken(
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Imt1c2hhbEBnbWFpbC5jb20iLCJpZCI6IjYyMmYxMzg0ODVlNzRjMjQzM2M4NGVmMCIsImlhdCI6MTY0NzI1MjM5NSwiZXhwIjoxNjc4Nzg4Mzk1fQ.nGKqS_-5AbzcyYlDHbLwSoSHR-xT10RDGOpRpbaNPKE"
  );
  try {
    const res = await axios.get(Get_News, { params: newsObj });
    dispatch({
      type: GET_HOMEPAGE_NEWS,
      payload: res.data.result,
    });
    return res;
  } catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
};

export const clearNewsData = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ALL_NEWS_DATA,
  });
};

export const getcms = (slug) => async (dispatch) => {
  try {
    const res = await axios.get(get_cms + slug);
    return res;
  } catch (err) {
    return err;
  }
};

export const getEvnewsDetails = (id) => async (dispatch) => {
  try {
    const res = await axios.get(Get_News_Details + "?id=" + id);
    if (res) {
      dispatch({
        type: GET_NEWS_DETAILS,
        payload: res.data.result,
      });
    }
  } catch (err) {
    return err;
  }
};

export const getBlog =
  ( dataObj ) =>
  async (dispatch) => {
    // console.log(dataObj,'dataObjdataObjdataObj')
    try {
      const res = await axios.get(Get_Blog+dataObj);

      if (res) {
        dispatch({
          type: GET_BLOG_LISTING,
          payload: res.data.result,
        });
      }
    } catch (err) {
      return err;
    }
  };
export const getBlogDetails =
  ({ dataObj }) =>
  async (dispatch) => {
    try {
      const res = await axios.get(Get_Blog, { params: dataObj });
      if (res) {
        dispatch({
          type: GET_BLOG_DETAILS,
          payload: res.data.result,
        });
      }
      return res.data.result;
    } catch (err) {
      return err;
    }
  };

export const addlocal = () => async (dispatch) => {
  try {
    const res = await localStorage.getItem("saved_vehicles");
    if (res) {
      dispatch({
        type: ADD_LOCAL,
        payload: JSON.parse(res),
      });
    }
  } catch (err) {
    return err;
  }
};

export const clearlocal = () => async (dispatch) => {
  try {
    dispatch({
      type: CLEAR_LOCAL,
    });
  } catch (err) {
    return err;
  } 
}
export const getListSimilarNews=(dataObj)=>async(dispatch)=>{
  // console.log(dataObj,'dataaaaa')
  try{
    const res =await axios.get(similarNewsList+dataObj);
    if(res) {
      dispatch({
        type : GET_LIST_SIMILAR_NEWS,
        payload : res.data.result
      })
    }
  }catch(err){

  }
}


