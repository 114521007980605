import React, { Fragment, useState } from 'react';
import Link from 'next/link';
import phone from '../public/images/Path 2286.png';
import mail from '../public/images/Path 2284.png';
import address from '../public/images/Path 2288.png';
import logo from '../public/images/logo-b.png';
import { withRouter } from 'react-router';
import { checkEmailValidation } from '../helpers/Validation';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as serviceActions from '../actions/serviceActions';
import { Button } from '../common_components/CommonFields';
import { PulseLoader } from 'react-spinners';
import { css } from '@emotion/react';
import ReactGA from 'react-ga';
import Image from 'next/image';
import { IoMdCall } from "react-icons/io";
import { FaEnvelope } from "react-icons/fa";
import {MdLocationOn} from "react-icons/md";
import Analytics from '../common_components/Common/Analytics';
import { FaFacebookF } from "react-icons/fa6";
// import { IoLocation } from "react-icons/io";
import { FaInstagram } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { isDesktop, isMobile, isMobileOnly } from "react-device-detect";

// import verlogo from '../public/images/Logo_vertical-white.png';
function DefaultFooter() {
  const dispatch = useDispatch();
  const { subscribeUser } = bindActionCreators(serviceActions, dispatch);
  const [saveLoading, setSaveLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const year = new Date().getFullYear()
  const onHandleSubmit = (e) => {
    e.preventDefault();

    let errEmail = '';

    errEmail = checkEmailValidation(email, 'email');

    if (errEmail) {
      setErrorEmail(errEmail);
    } else {
      setErrorEmail('');

      setSaveLoading(true);
      subscribeUser({ email: email }).then(() => {
        setEmail('');
        setSaveLoading(false);
      });
      HandleEvents("Subscribe Button")
    }
  };
  
  const HandleEvents = (data) => {
    // console.log(data)
    // console.log(data.name)
    const Analyticsprops = {
      GA: true,
      title: `${data} ${data === "Subscribe Button" ? "" : "Link"} - Footer `,
      category: 'Footer-Events',
      action: `${data} ${data === "Subscribe Button" ? "" : "Link"} - Footer `,
      label: `${data} ${data === "Subscribe Button" ? "" : "Link"} - Footer clicked!`
    }
    Analytics(Analyticsprops)
}


  const override = css`
    display: block;
    margin: 0 auto;
    border-color: blue;
    color: blue;
  `;

  return (
    <Fragment>
      <footer>
        <div className="bg-white" style={{ borderTop: "1px solid #F0F0F0" }}>
          <div className="container px-4 px-sm-0 d-flex w-100">
            <div className="row w-100 m-0 ">
              <div className={`col-lg-3  order-1  px-md-3  pt-md-4 mb-0  footer-logo-container`}>
                <Image
                  src={logo}
                  className="footer-logo "
                  alt="logo"
                  onClick={() => HandleEvents("Footer logo")}
                />
                {/* <p className='logo-tagline'>Your Ev journey starts here!</p> */}
              </div>
              <div className="col-lg-4 col-md-8  px-sm-1 order-2  px-3 pt-2 pt-lg-4 pt-md-3  d-flex flex-column justify-content-center  color-d-gray footer-border footer-contact">
                <div className="footer-sec-1 mb-3 mx-lg-3 mx-sm-4">
                  <a
                    href="tel:+91 9099900563"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => HandleEvents("call")}
                  >
                    {/* <Image src={phone} alt='' width='15'  /> */}
                    <IoMdCall
                      style={{ fontSize: "20px", marginRight: "15px" }}
                    />
                    <span id="call">+91 9099900563</span>
                  </a>
                </div>
                <div className="footer-sec-1 mb-3 mx-lg-3  mx-sm-4">
                  <a
                    href="mailto:info@evindia.online"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => HandleEvents("mail")}
                  >
                    {/* <Image src={mail} alt='' width='16' /> */}
                    <FaEnvelope
                      style={{ fontSize: "20px", marginRight: "15px" }}
                    />
                    <span>info@evindia.online</span>
                  </a>
                </div>
                {/* <div className='footer-sec-1 mb-3 mx-lg-3  mx-sm-4'>
                  <Image src={mail} alt='' width='16' />
                  <span>business@evindia.online</span>
                </div> */}
                {/* <div className=' footer-sec-1 mb-3 mx-lg-3  mx-sm-4'>
                  <a href='https://goo.gl/maps/HDyFn1T8iKszv6388' target="_blank"  rel="noreferrer" onClick={() => HandleEvents("Address")}>
                 
                  <MdLocationOn style={{fontSize : "20px",marginRight:"15px"}} />
                  <span>Ahmedabad , Gujarat , India </span>
                  </a>
                </div> */}
              </div>
              {/* pt-2 pt-lg-4 pt-md-3 pb-md-3 
              subscribe-padding*/}
              <div className="col-lg-5 col-md-8  order-last p-lg-4 p-md-3 mb-3 mb-md-0  footer-border">
                <div className="col-12">
                  <p className="fs-5  fw-normal  w-100 ">
                    Subscribe to EVINDIA to get electrifying updates!
                  </p>
                </div>
                <div className="col-12  d-flex align-self-center  ">
                  <div className="row col-12 ">
                    <div className="col-12 col-lg-7 ">
                      <input
                        type="text"
                        className="form-control footer-int px-3 w-100 "
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {
                        <div className="text-danger ps-0 ps-sm-3 ps-md-5 mt-1 d-block d-sm-none">
                          {errorEmail}
                        </div>
                      }
                    </div>
                    <div className="col-12 col-lg-5">
                      <Button
                        className={
                          "search-btn btn subscribe-btn  mx-0  px-0 w-100 mt-2 mt-md-0"
                        }
                        title={
                          saveLoading ? (
                            <PulseLoader
                              color={"#000"}
                              size={8}
                              css={override}
                            />
                          ) : (
                            "Subscribe"
                          )
                        }
                        click={(e) => onHandleSubmit(e)}
                      />
                    </div>
                  </div>
                </div>
                {
                  <div className="text-danger ps-5 d-none d-md-block">
                    {errorEmail}
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="bg-dark ">
          <div className="py-3 container">
          <div className="row col-12 m-0">
  {/* Conditional classes for flex direction and border colors */}
  <div className={`d-flex ${isMobile ? 'flex-column' : 'flex-row-reverse'}  text-white col-12 col-lg-8 col-md-12 order-2 order-sm-1 `}>
  
    
    {/* text-align */}
    <div className={`col-12 col-lg-6 col-md-12 text-center ${isMobile ? 'order-2 mt-2 ' : 'order-1'}`}>
      {/* <span className=" w-50 d-inline-block  col-sm-12 col-md-6 col-6 footer-social-1" onClick={() => HandleEvents("Terms and conditions")}>
        <Link href="/termsandconditions">Terms & Conditions</Link>
      </span>

      <span className="w-50 d-inline-block col-sm-12 col-md-6 col-6 footer-social-1" onClick={() => HandleEvents("Privacy policy")}>
        <Link href="/privacy-policy">Privacy</Link>
      </span> */}

      {/* <div className='text-center'> */}
          <Link href='/termsandconditions'>
            <a onClick={() => HandleEvents("Terms and conditions")}>
              <span className={`${isMobile ? 'fs-9' : 'fs-8'} pe-3 border-right`}>
                TERMS
              </span>
            </a>
          </Link>
          <Link href='/privacy-policy'>
            <a onClick={() => HandleEvents("Privacy policy")}>
              <span className={`${isMobile ? 'fs-9' : 'fs-8'} px-3  border-right`}> PRIVACY</span>
            </a>
          </Link>
          <Link href='/career'>
            <a onClick={() => HandleEvents("Career")}>
              <span className={`${isMobile ? 'fs-9' : 'fs-8'} ps-3`}> CAREER</span>
            </a>
          </Link>
        {/* </div> */}
    </div>
    
    {/*  d-flex justify-content-center */}
    <div className={`d-lg-inline col-12 col-lg-6 col-md-12 footer-social-1 ${isMobile ? 'order-2 mt-2 d-flex justify-content-center' : 'order-1'}`}>
      © Copyright {year} Vidhyut Vahanam Tyd Pvt Ltd
    </div>
  </div>
  
  {/* Social media icons section */}
  <div className="col-lg-4 col-sm-12 col-12 social-icon  order-sm-2 d-flex justify-content-center">
    <div className='d-flex justify-content-around w-75 '>
      <a href="https://www.facebook.com/evindia.online/" target="_blank" rel="noreferrer" aria-label="link for EVINDIA facebook page" onClick={() => HandleEvents("Facebook")}>
        <FaFacebookF className="footer-icon mx-md-3 mx-2 social-media-icon" />
      </a>
      <a href="https://www.instagram.com/evindia.online/?utm_medium=copy_link" target="_blank" rel="noreferrer" aria-label="link for EVINDIA instagram page" onClick={() => HandleEvents("Instagram")}>
        <FaInstagram className="mx-md-3 mx-2 footer-icon" />
      </a>
      <a href="https://www.linkedin.com/company/evindiaonline/" target="_blank" rel="noreferrer" aria-label="link for EVINDIA linkedin page" onClick={() => HandleEvents("Linkedin")}>
        <FaLinkedinIn className="md-3 mx-2 footer-icon" />
      </a>
      <a href="https://youtube.com/channel/UCywB8N5ChjnfTNrvS4q8ZIA" target="_blank" rel="noreferrer" aria-label="link for EVINDIA Youtube page" onClick={() => HandleEvents("Youtube")}>
        <FaYoutube className="mx-md-3 mx-2 footer-icon" />
      </a>
      <a href="https://twitter.com/EVIndiaonline" target="_blank" rel="noreferrer" aria-label="link for EVINDIA twitter page" onClick={() => HandleEvents("Twitter")}>
        <FaXTwitter className="mx-md-3 mx-2 footer-icon" />
      </a>
    </div>
  </div>
</div>

          </div>
        </div>
      </footer>
    </Fragment>
  );
}
export default DefaultFooter;

 {/* {`bordered d-flex ${isMobile ? 'flex-row border border-warning' : 'flex-col border border-success'} w-66 text-white col-12  col-lg-8 col-md-12  order-2 order-sm-1 mt-2 mt-sm-0 footer-social-container`} */}