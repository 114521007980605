import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducers from "../reducers";
import { HYDRATE,createWrapper } from "next-redux-wrapper";
import { composeWithDevTools } from "redux-devtools-extension";

const initialState = {};

const middleware = [thunk];
const masterReducer = (state, action) => {
  if (action.type === HYDRATE) {

    // console.log(action.payload.home);
    const nextState = {
      ...state, // use previous state
      demousers: [
        ...new Set([...action.payload.demousers, ...state.demousers]),
      ],
      home: { ...action.payload.home },
      company: { ...action.payload.company },
      service: { ...action.payload.service },
      upcoming: { ...action.payload.upcoming },
      vehicle: { ...action.payload.vehicle },
      event: { ...action.payload.event },
      acordian: { ...action.payload.acordian },
      compare: { ...action.payload.compare },
      user: {...action.payload.user },
      dealer: {...action.payload.dealer },
      tab:{...action.payload.tab}
    };

    if (state.count) nextState.count = state.count; // preserve count value on client side navigation
    return nextState;
  } else {
    return rootReducers(state, action);
  }
};

const initStore = () => {
  return createStore(masterReducer, composeWithDevTools(applyMiddleware(...middleware)))
}


export const wrapper =  createWrapper(initStore);
