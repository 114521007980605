import {
  GET_OWNED_VEHICLES,
  GET_USER_TIMELINE,
  USER_EV_JOURNEY,
  USER_LOADING_SUCCESS,
  USER_POSTS,
  USER_REVIEWS,
  USER_LOGIN,
  CLEAR_USER,
  GET_OTHER_USER,
  GET_USER_OWNED_VEHICLE_EXPERIANCE,
  CLEAR_OTHER_USER,
  DEALER_VEHICLE,
  DEALER_REVIEWS_BY_USER,
  PROFILE_POINT
} from '../actions/types';
const initialState = {
  token:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Imt1c2hhbEBnbWFpbC5jb20iLCJpZCI6IjYxNjUzOTIwMzkwMzRiMzBkODgyZmY5NCIsImlhdCI6MTYzODc4NzQ5NSwiZXhwIjoxNjcwMzIzNDk1fQ.9_ylQKLt7V51TbQ62uriKewt0zx30tziMyeE84WG5M4',
  isAuthenticated: null,
  loading: true,
  user: null,
  profilepoint: null,
  otheruser: [],
  otherUserLoading: true,
  uservehicles: [],
  userVehiclesLoading: true,
  myEVJourney: [],
  journeyLoading: true,
  userPosts: [],
  userPostLoading: true,
  userReviews: [],
  userReviewsLoading: true,
  dealerReviewsbyUser: [],
  dealerReviewsbyUserLoading: true,
  userTimeLine: {
    docs: [],
    page: 1,
    totalPages: 1,
    hasPrevPage: false,
    hasNextPage: false,
  },
  userTimeLineLoading: true,
  ownedVehicleLoading: true,
  ownedVehicles: [],
  ownedVehicleexperianceLoading: true,
  ownedVehiclesexperiance: [],
  dealerVehicle: [],
  dealerVehicleLoading: true,
};

const userReducers = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case USER_LOADING_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload,
      };
    case USER_LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload,
        token: payload.token,
      }
    case CLEAR_USER:
      return {
        ...state,
        isAuthenticated: false,
        user: [],
      }
    case PROFILE_POINT:
      return {
        ...state,
        profilepoint: payload
      }
    case CLEAR_OTHER_USER:
      return {
        ...state,
        otherUserLoading: true,
        otheruser: [],
      }
    case GET_OTHER_USER:
      return {
        ...state,
        otheruser: payload,
        otherUserLoading: false,
      }
    case USER_EV_JOURNEY:
      return {
        ...state,
        journeyLoading: false,
        myEVJourney: payload,
      };
    case USER_POSTS:
      return {
        ...state,
        userPostLoading: false,
        userPosts: payload,
      };
    case USER_REVIEWS:
      return {
        ...state,
        userReviewsLoading: false,
        userReviews: payload,
      };
    case DEALER_REVIEWS_BY_USER:
      return {
        ...state,
        dealerReviewsbyUserLoading: false,
        dealerReviewsbyUser: payload,
      };
    case GET_USER_TIMELINE:
      return {
        ...state,
        userTimeLineLoading: false,
        userTimeLine: payload,
      };
    case GET_OWNED_VEHICLES:
      return {
        ...state,
        ownedVehicleLoading: false,
        ownedVehicles: payload,
      };
    case GET_USER_OWNED_VEHICLE_EXPERIANCE:
      return {
        ...state,
        ownedVehicleexperianceLoading: false,
        ownedVehiclesexperiance: payload,
      };

    case DEALER_VEHICLE:
      return {
        ...state,
        dealerVehicleLoading: false,
        dealerVehicle: payload,
      };
    default:
      return state;
  }
};

export default userReducers;
