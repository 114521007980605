import axios from "axios";
import {
  Add_User_Blog,
  Add_User_Car_Reviews,
  config,
  Get_Owned_Vehicles,
  Get_User_Timeline,
  Load_User,
  User_EV_Journey,
  User_Posts,
  User_Reviews,
  Dealer_Reviews,
  user_signUp,
  user_Login,
  edit_user,
  get_other_user,
  add_user_owned_vehicles,
  upload_images_for_ev_experiance,
  Get_owned_vehicles_with_experiances,
  delete_ev_experiance,
  add_user_post,
  add_comment,
  get_comments,
  add_upvote_downvote,
  get_other_user_by_slug,
  forgot_password,
  verify_otp,
  reset_password,
  social_login,
  add_vehicle_dealer,
  clone_location,
  get_dealer_vehicle,
  public_Get_owned_vehicles_with_experiances,
  public_User_Posts,
  public_User_Reviews,
  public_Get_Owned_Vehicles,
  profile_tracker,
  delete_user,
  reset_username
} from "../api";
import setAuthToken from "../helpers/setAuthToken";
import {
  GET_OWNED_VEHICLES,
  GET_USER_TIMELINE,
  USER_EV_JOURNEY,
  USER_LOADING_SUCCESS,
  USER_POSTS,
  USER_REVIEWS,
  DEALER_REVIEWS_BY_USER,
  USER_BY_VEHICLE_ID,
  CLEAR_USER,
  GET_OTHER_USER,
  GET_USER_OWNED_VEHICLE_EXPERIANCE,
  GET_USER_POST,
  CLEAR_OTHER_USER,
  DEALER_VEHICLE,
  PROFILE_POINT
} from "./types";
import { toast } from "react-toastify";

// Get User Details Function
export const loadUser = () => async (dispatch) => {
  let token = localStorage.getItem("token")
  if (token) {
    setAuthToken(token);
    try {
      const res = await axios.get(Load_User);
      dispatch({
        type: USER_LOADING_SUCCESS,
        payload: res.data.result,
      });
      return res;
    } catch (error) {
      if (error.response !== undefined) {
        toast.error(error.response.data.message);
        if (token) localStorage.removeItem('token')
      } else {
        toast.error("Something went wrong, Please try again later!");
      }
      return error;
    }
  }
};

export const checklUserAuth = () => async (dispatch) => {
  let token = localStorage.getItem("token")
  if (token) {
    setAuthToken(token);
    try {
      const res = await axios.get(Load_User);
      return true;
    } catch (error) {
      return false;
    }
  } else {
    return false;
  }
};

// Get User EV Journey Function
export const getEVJourneyOfUser = () => async (dispatch) => {
  try {
    const res = await axios.get(User_EV_Journey);
    if (res) {
      dispatch({
        type: USER_EV_JOURNEY,
        payload: res.data.result,
      });
    }
    return res;
  } catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
};

// Get User Posts Function
export const getUserPosts = (page) => async (dispatch) => {
  try {
    const res = await axios.get(User_Posts + `?page=${page}`);
    if (res) {
      dispatch({
        type: USER_POSTS,
        payload: res.data.result,
      });
    }
    return res;
  } catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
};
export const publicGetUserPosts = (id, page) => async (dispatch) => {
  try {
    const res = await axios.get(public_User_Posts + id + `&page=${page}`);
    if (res) {
      dispatch({
        type: USER_POSTS,
        payload: res.data.result,
      });
    }
    return res;
  } catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
};

// Get User Reviews Function
export const getUserReviews = (page, limit) => async (dispatch) => {
  if (localStorage.getItem("token")) {
    let token = localStorage.getItem("token")
    setAuthToken(token);
  }
  try {
    const res = await axios.get(User_Reviews + `?page=${page}&limit=${limit}`);
    if (res) {
      dispatch({
        type: USER_REVIEWS,
        payload: res.data.result,
      });
    }
    return res;
  } catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
};
export const publicGetUserReviews = (id, page) => async (dispatch) => {
  try {
    const res = await axios.get(public_User_Reviews + id + `&page=${page}`);
    if (res) {
      dispatch({
        type: USER_REVIEWS,
        payload: res.data.result,
      });
    }
    return res;
  } catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
};

// Get dealer reviews given by user
export const getDealerReviewsByUser = (user, page, limit) => async (dispatch) => {
  if (localStorage.getItem("token")) {
    let token = localStorage.getItem("token")
    setAuthToken(token);
  }
  try {
    const res = await axios.get(Dealer_Reviews + `?page=${page}&user=${user}&limit=${limit}`);
    if (res) {
      dispatch({
        type: DEALER_REVIEWS_BY_USER,
        payload: res.data.result,
      });
    }
    return res;
  } catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
};

// Get User Timeline Function
export const getUserTimeline = (paginationData) => async (dispatch) => {
  if (localStorage.getItem("token")) {
    let token = localStorage.getItem("token")
    setAuthToken(token);
  }

  try {
    const res = await axios.get(Get_User_Timeline, { params: paginationData });

    if (res) {
      // types: 1=post,2=blog,3=ev_journey,4=car_review
      dispatch({
        type: GET_USER_TIMELINE,
        payload: res.data.result,
      });
    }
    return res;
  } catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
};

// Get User Owned vehicles function

export const getUserOwnedVehicles = (page) => async (dispatch) => {
  if (localStorage.getItem("token")) {
    let token = localStorage.getItem("token")
    setAuthToken(token);
  }
  try {
    const res = await axios.get(Get_Owned_Vehicles);
    if (res) {
      dispatch({
        type: GET_OWNED_VEHICLES,
        payload: res.data.result,
      });
    }
    return res;
  } catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
};

export const publicGetUserOwnedVehicles = (id) => async (dispatch) => {
  try {
    const res = await axios.get(public_Get_Owned_Vehicles + id);
    if (res) {
      dispatch({
        type: GET_OWNED_VEHICLES,
        payload: res.data.result,
      });
    }
    return res;
  } catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
};

export const addUserCarReviews = (reviewObj) => async (dispatch) => {
  try {
    const body = JSON.stringify(reviewObj);
    const res = await axios.post(Add_User_Car_Reviews, body, config);
    if (res) {
      toast.success(res.data.message);
    }
    return res;
  } catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
};

export const addUserBlogs = (blogData) => async (dispatch) => {
  try {
    const res = await axios.post(Add_User_Blog, blogData);
    if (res) {
      toast.success(res.data.message);
    }
    return res;
  } catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
};

export const userSignup = (formData) => async (dispatch) => {
  const res = await axios.post(user_signUp, formData)
  try {
    if (res) {
      // toast.success(res.data.message);
      return res;
    }
  }
  catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
}

export const userLogin = (formData) => async (dispatch) => {
  const res = await axios.post(user_Login, formData);
  dispatch({
    type: USER_LOADING_SUCCESS,
    payload: res.data.result,
  });
  try {
    if (res) {
      toast.success(res.data.message);
      localStorage.setItem("token", res.data.token);
      return res;
    }
  }
  catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
}

export const forgotPassword = (formData) => async (dispatch) => {
  const res = await axios.post(forgot_password, formData);

  try {
    if (res) {
      toast.success(res.data.message);
      return res;
    }
  }
  catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
}

export const verifyOtpForForgotPassword = (formData) => async (dispatch) => {
  const res = await axios.post(verify_otp, formData);

  try {
    if (res) {
      toast.success(res.data.message);
      return res;
    }
  }
  catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
}

export const resetPassword = (email, formData) => async () => {
  const res = await axios.patch(reset_password + email, formData);

  try {
    if (res) {
      toast.success(res.data.message);
      return res;
    }
  }
  catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
}

export const resetUsername = (email, formData) => async () => {
  const res = await axios.patch(reset_username + email, formData);
  try {
    if (res) {
      toast.success(res.data.message);
      return res;
    }
  }
  catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
}

export const editUser = (id, formData) => async (dispatch) => {
  const res = await axios.put(edit_user + id, formData);
  try {
    if (res) {
      toast.success(res.data.message);
      return res;
    }
  }
  catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
}

export const deleteUser = (id) => async (dispatch) => {
  const res = await axios.delete(delete_user + id);
  localStorage.removeItem("role_id")
  dispatch({
    type: CLEAR_USER,
  });
  try {
    if (res) {
      return res;
    }
  }
  catch (error) {
    return error;
  }
}

export const logoutUser = () => async (dispatch) => {
  localStorage.removeItem("role_id")
  dispatch({
    type: CLEAR_USER,
  });
}

export const clearOtheruser = () => async (dispatch) => {
  dispatch({
    type: CLEAR_OTHER_USER,
  });
}

export const getprofilepoint = (id) => async (dispatch) => {
  const res = await axios.get(profile_tracker + `?id=${id}`);
  try {
    if (res) {
      dispatch({
        type: PROFILE_POINT,
        payload: res.data.result
      });
    }
  } catch (error) {
    return error;
  }
}

export const getOtherUserById = (id) => async (dispatch) => {
  const res = await axios.patch(get_other_user + id);
  try {
    if (res) {
      dispatch({
        type: GET_OTHER_USER,
        payload: res.data.result,
      })
      return res;
    }
  }
  catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
}
export const getOtherUserBySlug = (slug) => async (dispatch) => {
  const res = await axios.patch(get_other_user_by_slug + slug);
  try {
    if (res) {
      dispatch({
        type: GET_OTHER_USER,
        payload: res.data.result,
      })
      return res;
    }
  }
  catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
}

export const addUserOwnedVehicles = (addObj) => async (dispatch) => {
  if (localStorage.getItem("token")) {
    let token = localStorage.getItem("token")
    setAuthToken(token);
  }
  const res = await axios.post(add_user_owned_vehicles, addObj);
  try {
    if (res) {
      return res;
    }
  }
  catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
}
export const uploadImagesForEvExperiance = (id, uploadObj) => async (dispatch) => {
  const res = await axios.put(upload_images_for_ev_experiance + id, uploadObj);
  try {
    if (res) {
      return res;
    }
  }
  catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
}
export const getUserOwnedVehicleswithExperiance = (id) => async (dispatch) => {
  if (localStorage.getItem("token")) {
    let token = localStorage.getItem("token")
    setAuthToken(token);
  }
  try {
    const res = await axios.patch(Get_owned_vehicles_with_experiances + id);
    if (res) {
      dispatch({
        type: GET_USER_OWNED_VEHICLE_EXPERIANCE,
        payload: res.data.result,
      });
    }
    return res;
  } catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
};
export const publicGetUserOwnedVehicleswithExperiance = (id) => async (dispatch) => {
  try {
    const res = await axios.get(public_Get_owned_vehicles_with_experiances + id);
    if (res) {
      dispatch({
        type: GET_USER_OWNED_VEHICLE_EXPERIANCE,
        payload: res.data.result,
      });
    }
    return res;
  } catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
};
export const editEvExperiance = (id, editObj) => async (dispatch) => {
  if (localStorage.getItem("token")) {
    let token = localStorage.getItem("token")
    setAuthToken(token);
  }

  try {
    const res = await axios.put(Get_owned_vehicles_with_experiances + id, editObj);
    if (res) {
      return res;
    }
  } catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
};
export const deleteEvExperiance = (id, title) => async (dispatch) => {
  if (localStorage.getItem("token")) {
    let token = localStorage.getItem("token")
    setAuthToken(token);
  }

  try {
    const res = await axios.delete(delete_ev_experiance + id + "?topic=" + title);
    if (res) {
      return res;
    }
  } catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
};
export const deleteOwnedVehicle = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(add_user_owned_vehicles + "/" + id);
    if (res) {
      return res;
    }
  } catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
};

//************************user-post-API's************************//

export const addUserPost = (addObject) => async (dispatch) => {
  const res = axios.post(add_user_post, addObject);
  try {
    if (res) return res;
  }
  catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
}
export const editPost = (id, updateObj) => async (dispatch) => {


  const res = await axios.put(add_user_post + "/" + id, updateObj)
  try {
    if (res) return res
  }
  catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
}

export const deletePost = (id) => async (dispatch) => {


  const res = await axios.delete(add_user_post + "/" + id)
  try {
    if (res) return res
  }
  catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
}

export const addComment = (addObject) => async (dispatch) => {


  const res = axios.post(add_comment, addObject);
  try {
    if (res) return res;
  }
  catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
}

export const getcomments = (post, limit, page, search) => async (dispatch) => {

  const res = axios.get(get_comments + "?post=" + post + "&limit=" + limit);
  try {
    if (res) return res;
  }
  catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
}

export const addUpvoteDownvote = (id, addObject) => async (dispatch) => {


  const res = axios.put(add_upvote_downvote + id, addObject);
  try {
    if (res) return res;
  }
  catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
}

//******************************Other user Api's************************************//

export const getOtheruserOwnedVehicles = (id) => async (dispatch) => {
  try {
    const res = await axios.get(Get_Owned_Vehicles + id);
    if (res) {
      dispatch({
        type: GET_OWNED_VEHICLES,
        payload: res.data.result,
      });
    }
    return res;
  } catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
};

export const getOtherUserPosts = (id, page) => async (dispatch) => {
  try {
    const res = await axios.get(User_Posts + `?id=${id}&page=${page}`);
    if (res) {
      dispatch({
        type: USER_POSTS,
        payload: res.data.result,
      });
    }
    return res;
  } catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
};

export const getOtherUserReviews = (id, page) => async (dispatch) => {

  try {
    const res = await axios.get(User_Reviews + `?id=${id}&page=${page}`);
    if (res) {
      dispatch({
        type: USER_REVIEWS,
        payload: res.data.result,
      });
    }
    return res;
  } catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
};

export const socialLogin = (reqdata) => async (dispatch) => {

  const res = await axios.post(social_login, reqdata);

  try {
    const resData = res.data ?? null
    if (resData && resData.data && resData.data.token) {
      localStorage.setItem("token", resData.data.token);
    }
    if (res && res.data && res.data.error) {
      toast.error(res.data.error);
    }
    return res;
  }
  catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
}

export const addVehicleForDealer = (addObj) => async (dispatch) => {
  if (localStorage.getItem("token")) {
    let token = localStorage.getItem("token")
    setAuthToken(token);
  }


  const res = await axios.post(add_vehicle_dealer, addObj);
  try {
    if (res) {
      return res;
    }
  }
  catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
}

export const getDealerVehicle = () => async (dispatch) => {

  try {
    const res = await axios.get(get_dealer_vehicle);
    if (res) {
      dispatch({
        type: DEALER_VEHICLE,
        payload: res.data.result,
      });
    }
    return res;
  } catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
};


export const cloneCitiesfromDealers = (vehicle, dealer) => async (dispatch) => {
  try {
    const res = await axios.get(clone_location + '?vehicle=' + vehicle + '&dealer=' + dealer)
    return res
  } catch (err) {
    return err;
  }
}
