export const GET_POPULAR_VEHICLES = 'GET_POPULAR_VEHICLES';
export const GET_LATEST_VEHICLES = 'GET_LATEST_VEHICLES';
export const GET_DEALER_CITIES = 'GET_DEALER_CITIES';
export const GET_CITIES = 'GET_CITIES';
export const GET_STATES = 'GET_STATES';
export const GET_WAITING_PERIOD = 'GET_WAITING_PERIOD';
export const GET_VEHICLE_DETAILS = 'GET_VEHICLE_DETAILS';
export const GET_UPCOMING_VEHICLES = 'GET_UPCOMING_VEHICLES';
export const USER_LOADING_SUCCESS = 'USER_LOADING_SUCCESS';
export const USER_EV_JOURNEY = 'USER_EV_JOURNEY';
export const COMPARE_VEHICLE = 'COMPARE_VEHICLE';
export const GET_COMMUNITY_FORUM = 'GET_COMMUNITY_FORUM';
export const GET_COMPANY_LIST = 'GET_COMPANY_LIST';
export const GET_COMPANY_POST_LIST = 'GET_COMPANY_POST_LIST';
export const GET_RANDOM_COMPANY_LIST = 'GET_RANDOM_COMPANY_LIST';
export const GET_GENERAL_COMPANY_LIST = 'GET_GENERAL_COMPANY_LIST';
export const USER_POSTS = 'USER_POSTS';
export const GET_USER_TIMELINE = 'GET_USER_TIMELINE';
export const USER_REVIEWS = 'USER_REVIEWS';
export const DEALER_REVIEWS_BY_USER = 'DEALER_REVIEWS_BY_USER';
export const GET_ALL_UPCOMING_LIST = 'GET_ALL_UPCOMING_LIST';
export const GET_ALL_HOME_UPCOMING_LIST = 'GET_ALL_HOME_UPCOMING_LIST';
export const GET_ALL_COMPANY_LIST = 'GET_ALL_COMPANY_LIST';
export const GET_ALL_COMPANY_LIST_WITHOUT_PAGINATION =
  'GET_ALL_COMPANY_LIST_WITHOUT_PAGINATION';
export const GET_COMPANY_DETAILS = 'GET_COMPANY_DETAILS';
export const GET_ALL_SPECIFICATIONS = 'GET_ALL_SPECIFICATIONS';
export const GET_MAIN_VEHICLES = 'GET_MAIN_VEHICLES';
export const GET_VEHICLE_VARIANTS = 'GET_VEHICLE_VARIANTS';
export const GET_VEHICLE_COLOR_LIST='GET_VEHICLE_COLOR_LIST'
export const GET_COMPANY_INFRASTRUCTURES = 'GET_COMPANY_INFRASTRUCTURES';
export const GET_OWNED_VEHICLES = 'GET_OWNED_VEHICLES';
export const GET_VEHICLES_BY_COMPANY = 'GET_VEHICLES_BY_COMPANY';
export const GET_ALL_VEHICLE_SAVED = 'GET_ALL_VEHICLE_SAVED';
export const GET_EMPTY_SAVED = 'GET_EMPTY_SAVED';
export const ERROR_VEHICLE_SAVED = 'ERROR_VEHICLE_SAVED';
export const APPLY_FILTER = 'APPLY_FILTER';
export const GET_ALL_VEHICLE_NEWS = 'GET_ALL_VEHICLE_NEWS';
export const GET_ALL_COMPANY_NEWS = 'GET_ALL_COMPANY_NEWS';
export const GET_RANDOM_NEWS = 'GET_RANDOM_NEWS';
export const GET_FEATURE_NEW = 'GET_FEATURE_NEW';
export const GET_HOMEPAGE_NEWS = 'GET_HOMEPAGE_NEWS';
export const CLEAR_ALL_NEWS_DATA = 'CLEAR_ALL_NEWS_DATA';
export const STATE_NAME = 'STATE_NAME';
export const CITY_NAME = 'CITY_NAME';
export const DEALERS_DETAILS = 'DEALERS_DETAILS';
export const DEALERS_DETAILS_BRANDS = 'DEALERS_DETAILS_BRANDS';
export const DEALER_PROFILE = 'DEALER_PROFILE';
export const DEALER_PROFILE_VEHICLE = 'DEALER_PROFILE_VEHICLE';
export const DEALER_PROFILE_BRANDS = 'DEALER_PROFILE_BRANDS';
export const COMPANY_DETAILS_LOADING = 'COMPANY_DETAILS_LOADING';
export const COMPANY_AVAILABLE_VEHICLES = 'COMPANY_AVAILABLE_VEHICLES';
export const LOAD_COMPANY_AVAILABLE_VEHICLES = 'LOAD_COMPANY_AVAILABLE_VEHICLES';
export const GET_TOP_TEN_COMPANIES = 'GET_TOP_TEN_COMPANIES';
export const USER_LOGIN = "USER_LOGIN";
export const PROFILE_POINT = "PROFILE_POINT";
export const USER_BY_VEHICLE_ID = "USER_BY_VEHICLE_ID";
export const CLEAR_USER = "CLEAR_USER";
export const GET_OTHER_USER = "GET_OTHER_USER";
export const  USER_OWNED_VEHICLE = "USER_OWNED_VEHICLE"
export const CLEAR_FILTER = "CLEAR_FILTER"
export const CITY_SELECT = "CITY_SELECT"
export const STATE_SELECT = "STATE_SELECT"
export const VEHICLE_SELECT = "VEHICLE_SELECT"
export const GET_SIMILIAR_VEHICLE = "GET_SIMILIAR_VEHICLE"
export const CLEAR_OTHER_USER = "CLEAR_OTHER_USER"
export const GET_VEHICLE_RANGE = "GET_VEHICLE_RANGE"
export const GET_NEWS_DETAILS = "GET_NEWS_DETAILS"
export const GET_BLOG_LISTING = "GET_BLOG_LISTING"
export const GET_BLOG_DETAILS="GET_BLOG_DETAILS"
export const GET_SIMILAR_BLOGS="GET_SIMILAR_BLOGS"
export const GET_SIMILAR_NEWS="GET_SIMILAR_NEWS"
export const GET_DEALER_PRICE_BREAKUP="GET_DEALER_PRICE_BREAKUP"
export const GET_VEHICLE_LIST_REVIEW = "GET_VEHICLE_LIST_REVIEW";
export const GET_VEHICLE_QUESTION_ANS = "GET_VEHICLE_QUESTION_ANS";
export const GET_VEHICLE_OVERVIEW_LIST = "GET_VEHICLE_OVERVIEW_LIST";
export const GET_LIST_SIMILAR_NEWS="GET_LIST_SIMILAR_NEWS"
export const HANDLE_SELECTED_CITY="HANDLE_SELECTED_CITY"
export const HANDLE_SELECTED_STATE="HANDLE_SELECTED_STATE"
//*****************************USER EV JOURNEY*************************//
export const GET_USER_OWNED_VEHICLE_EXPERIANCE = "GET_USER_OWNED_VEHICLE_EXPERIANCE"

//*****************************USER POST*************************//
export const GET_USER_POST = 'GET_USER_POST';


//*****************************DEALER VEHICLE*************************//
export const DEALER_VEHICLE = 'DEALER_VEHICLE';

//*****************************DEALER POAST*************************//
export const DEALER_POSTS = 'DEALER_POSTS';

//*****************************DEALER GALLERY*************************//
export const DEALER_GALLERY = 'DEALER_GALLERY';
export const DEALER_VIDEO = 'DEALER_VIDEO';

//*****************************DEALER REVIEW*************************//
export const DEALER_REVIEWS = 'DEALER_REVIEWS';
export const DEALER_BRANDS = 'DEALER_BRANDS';
export const GET_OTHER_DEALER = 'GET_OTHER_DEALER';

//*****************************DEALER DASHBOARD*************************//
export const GET_DEALER_LEAD ='GET_DEALER_LEAD'
export const GET_DEALER_CRM = 'GET_DEALER_CRM'
export const GET_DEALER_WALLET = 'GET_DEALER_WALLET' 
export const DEALER_COMMENT = 'DEALER_COMMENT'

//*****************************EVENTS*************************//
export const GET_EVENT_LIST = 'GET_EVENT_LIST'
export const GET_EVENT_DETAILS ='GET_EVENT_DETAILS'


//*****************************BRAND GALLERY*************************//
export const GET_GALLERY_IMAGES ='GET_GALLERY_IMAGES'
export const GET_GALLERY_VIDEOS ='GET_GALLERY_VIDEOS'

//*****************************SAVED VEHICLES*************************//

export const ADD_LOCAL = 'ADD_LOCAL'
export const CLEAR_LOCAL = 'CLEAR_LOCAL'

//*****************************COMPARE ACCORDIAN CHECKBOX*************************//

export const ACCORDIAN_KEY = 'ACCORDIAN_KEY'

//*****************************COMPARE VEHICLE*************************//

export const SET_COMPARE_VEHICLE = 'SET_COMPARE_VEHICLE'
export const SET_COMPARE_VEHICLE_ID = 'SET_COMPARE_VEHICLE_ID'
export const SET_COMPARE_VEHICLE_SPECIFICATION = 'SET_COMPARE_VEHICLE_SPECIFICATION'
export const GET_COMPARE_VEHICLE = 'GET_COMPARE_VEHICLE';

//*****************************FILTERATION*************************//

export const GET_FILTERED_VEHICLES = 'GET_FILTERED_VEHICLES';
export const GET_FILTERED_COMPANIES = 'GET_FILTERED_COMPANIES';
export const FILTER_VALUES = 'FILTER_VALUES';


//*****************************SUBMIT CONTACT US FORM*************************//

export const SET_CONTACT_FORM = 'SET_CONTACT_FORM'
export const ACTIVE_TAB_ON_CLICK = 'ACTIVE_TAB_ON_CLICK'

//*****************************Home Page*************************//

export const GET_LATEST_ELECTRIC_SCOOTER = 'GET_LATEST_ELECTRIC_SCOOTER';
export const GET_LATEST_ELECTRIC_BIKE = 'GET_LATEST_ELECTRIC_BIKE';
export const GET_LATEST_ELECTRIC_CAR = 'GET_LATEST_ELECTRIC_CAR';
export const GET_COMMERCIAL_VEHICLES = 'GET_COMMERCIAL_VEHICLES';
export const GET_FAQ_LIST = "GET_FAQ_LIST";
export const GET_LATEST_REVIEWS = "GET_LATEST_REVIEWS";
export const GET_BLOG_LIST = "GET_BLOG_LIST";
export const GET_BLOG_NEWS_LIST = "GET_BLOG_NEWS_LIST";
//*********************Home Page Background Banner Image*********************//

export const GET_HOMEPAGE_BANNER_IMAGE = 'GET_HOMEPAGE_BANNER_IMAGE'

//*********************Home Page Landingpage Background Banner Image*********************//
export const GET_HOMEPAGE_LP_BANNER_IMAGE = 'GET_HOMEPAGE_LP_BANNER_IMAGE'
export const SEND_REPORT = 'SEND_REPORT '

//*****************************BU4 PRODUCTS*************************//

 export const BU4_PRODUCTS = 'BU4_PRODUCTS'

 //*****************************Career Management*************************//

 export const JOB_LIST = 'JOB_LIST'
 export const JOB_DETAILS = 'JOB_DETAILS'

 //*****************************Popular Vehicle Images*************************//

 export const POPULAR_IMAGES = 'POPULAR_IMAGES'
 
 //*****************************Landing Page*************************//

 export const GET_LP_DETAILS_BY_COMPANY = 'GET_LP_DETAILS_BY_COMPANY'